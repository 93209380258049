@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        margin: 0;
        font-family: 'Be Vietnam Pro' ,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      
      code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
          monospace;
      }

    #root {
        /*background-color: #F1F1F9;*/
        height: 100vh;
    }

    .bg-gray-5 {
        background-color: #EAEDF2;
    }

}

@layer components {
    .bg-blue {
        background-color: blue;
    }

    .flex-col {
        flex-direction: column;
    }

    .flex-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .gap-x-1 {
        column-gap: 0.25rem;
    }

    .gap-x-2 {
        column-gap: 0.5rem;
    }

    .gap-x-3 {
        column-gap: 0.75rem;
    }
    
    .gap-x-4 {
        column-gap: 1rem;
    }

    .groupSettings >label {
        margin-inline-start: 0 !important;
    }
}

@layer utilities {
    .border-green-50:hover {
        border-color: #13B055;
     }
}